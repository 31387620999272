/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import NumberFormat from "react-number-format";
import axios from "axios";
import crypto from "crypto-browserify";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPopUpEvent,
  getParam,
} from "../../utils";
import { validateCardNumber } from "../../utils/payments";

const Payments = ({
  plan_id,
  planData,
  total_user_coins,
  textData,
  payments,
  urlparams,
  identity,
  validUser,
  product_id,
  platform_id,
  coupon_code,
  pushbar,
  setPushbar,
  tabHideShow,
  setTabHideShow,
  isFreeTrial,
}) => {
  const [isAgree, setIsAgree] = useState(true);
  /*
     //control autopay checkbox for users
    const planType=getParam(urlparams, 'plan_type').toLocaleLowerCase();
    let isValidUser=false;
    let allowCheckConsent=[];
    if(allowCheckConsent.includes(identity) && planType!=="event" && !/vod/.test(planType)){
        isValidUser=true;
    }
    */
  let isValidUser = false;
  const planType = getParam(urlparams, "plan_type")?.toLocaleLowerCase();
  /* exclude autopay consent checkbox for 3 days plan i.e plan id 57 */
  if (
    planType !== "event" &&
    !/vod/.test(planType) &&
    plan_id !== "57" &&
    plan_id !== "10"
  ) {
    isValidUser = true;
  }

  const [isValidName, setValidName] = useState(false);
  const [isValidNameSp, setValidNameSp] = useState(false);
  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidMonth, setValidMonth] = useState(false);
  const [isValidCvv, setValidCvv] = useState(false);
  const [freeTrialCCValid, setfreeTrialCCValid] = useState(false);

  /* Params */
  const user_upgradable = new URLSearchParams(urlparams).get("upgradable");
  // const platform_id = new URLSearchParams(urlparams).get("platform_id");
  const country = new URLSearchParams(urlparams).get("country")
    ? new URLSearchParams(urlparams).get("country")
    : "IN";
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventGatwayTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  var showCoinsTab = false;
  var plan_price_for_enable_coins = false;
  var amt_to_enable_coins = 0;
  var current_user_coins = total_user_coins;
  //   var current_user_coins = 30000;

  if (planData?.plan_price) {
    amt_to_enable_coins = planData?.plan_price * 40;
    //console.log(amt_to_enable_coins,'amt_to_enable_coins');
    if (current_user_coins >= amt_to_enable_coins) {
      plan_price_for_enable_coins = true;
    }
  }

  if (/Rent/.test(planData?.plan_dname) || /event/.test(planData?.plan_dname)) {
    showCoinsTab = false;
  } else {
    if (plan_price_for_enable_coins) {
      showCoinsTab = true;
    }
    if (user_upgradable === "1" && showCoinsTab) {
      showCoinsTab = false;
    } else if (user_upgradable === "1" && showCoinsTab === false) {
      showCoinsTab = false;
    } else if (user_upgradable === "0" && showCoinsTab === true) {
      showCoinsTab = true;
    }
  }

  // console.log(planData,'planData_mobile')

  const gaEventTracker = useAnalyticsEventGatwayTracker("Credit Debit Card");
  const gaEventTrackerHC = useAnalyticsEventGatwayTracker("Hungama Coins");
  //const gaEventTrackerWallet = useAnalyticsEventGatwayTracker('Wallet');

  var showGoogleWallet = false;
  if (platform_id === "1") {
    showGoogleWallet = true;
  }

  const validateCardNumberLoc = async (e, isFreeTrial) => {
    gaEventTracker("pay_now", planData?.plan_name + "_" + planData?.plan_price);
    var nameRegex = /^[A-Za-z\s]+$/;
    setValidName(false);
    setValidNameSp(false);
    setValidCardNumber(false);
    if (document?.getElementById("cardHolderName")?.value === "") {
      setValidName(true);
      e.preventDefault();
      return false;
    } else if (document?.getElementById("cardHolderName")?.value !== "") {
      if (!nameRegex?.test(document?.getElementById("cardHolderName")?.value)) {
        setValidName(false);
        setValidNameSp(true);
        e.preventDefault();
        return false;
      }
    }
    if (document.getElementById("cardNumber")?.value === "") {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    if (validateCardNumber(formData?.cardNumber?.replace(/ /g, ""))) {
      //console.log("Valid Card Number");
      // amplitudeBtnEvent(identity, "Btn_Submit_CCDC", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Submit_CCDC"
      );
    } else {
      setValidCardNumber(true);
      setValidName(false);
      //pay_validate = true;
      e.preventDefault();
      return false;
    }

    var result = true;
    var errMsg = "";
    var expire = document?.getElementById("expiryDate")?.value;
    if (!expire.match(/(0[1-9]|1[0-2])[/][0-9]{2}/)) {
      errMsg += "The expire date formate is not correct!\n";
      result = false;
    } else {
      // get current year and month
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;
      // get parts of the expiration date
      var parts = expire.split("/");
      var year = parseInt(parts[1], 10) + 2000;
      var month = parseInt(parts[0], 10);
      // compare the dates
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        errMsg += "The expiry date has passed.\n";
        result = false;
      }
    }
    console.log(errMsg);
    if (!result) {
      setValidMonth(true);
      e.preventDefault();
      return false;
    } else {
      setValidMonth(false);
    }
    if (document.getElementById("cvv")?.value === "") {
      setValidMonth(false);
      setValidCvv(true);
      e.preventDefault();
      return false;
    }

    //free trial
    if (
      isFreeTrial?.status === false &&
      isFreeTrial?.plan_id === plan_id &&
      isCreditDebit === "DC"
    ) {
      // console.log("wrong DC for freetrial");
      setValidCvv(false);
      setfreeTrialCCValid(true);
      e.preventDefault();
      return false;
    } else {
      setfreeTrialCCValid(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e?.target;
    // console.log(name);
    let data = { ...formData };
    // console.log(total_user_coins);
    data[name] = value;

    setFormData(data);
  };

  const handlePushbar = (e) => {
    console.log(e);
    const name = e?.target?.getAttribute("data-name");
    if (name === "verifyRedeemCoins") {
      // amplitudeBtnEvent(identity, "Btn_Cancel_Coins", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Cancel_Coins"
      );
      gaEventTrackerHC("Hungama Coins", "Cancel");
    }
    let value = e?.target?.getAttribute("value");
    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  const hungamaCoins = (e) => {
    // console.log(planData);
    // amplitudeBtnEvent(identity, "Btn_Select_Coins", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Select_Coins"
    );

    ampRabbitMQPopUpEvent({
      identity: identity,
      "Plan Name": planData?.plan_name,
      "Plan Validity": planData?.plan_valid,
      "Plan Value": planData?.plan_price?.toFixed(2),
      "Plan Currency": planData?.plan_currency,
      "Plan ID": plan_id,
      product_id: product_id,
      platform_id: platform_id,
      Action: "Redeem_Coins_Confirmation",
    });

    // console.log(e)
    const name = "verifyRedeemCoins";
    let value = e;
    setPushbar((prev) => {
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  // const [isLoading, setLoading] = useState(true);
  const [isCategoryCC, setIsCategoryCC] = useState(false);
  const [isCreditDebit, setIsCreditDebit] = useState("");
  // const [creditCardShow, setCreditCardShow] = useState(false);
  const [formData, setFormData] = useState({
    couponVerified: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
  });
  // const [pushbar, setPushbar] = useState({ verifyRedeemCoins: false });

  const handleOtherPayments = (e) => {
    if (e?.target?.getAttribute("data-name") === "verifyRedeemCoins") {
      // amplitudeBtnEvent(identity, "Btn_Submit_Coins", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_Submit_Coins"
      );
      gaEventTrackerHC("Hungama Coins", "Redeem Now");
    } else {
      // amplitudeBtnEvent(identity, "Btn_GoogleWallet", product_id, platform_id);
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_GoogleWallet"
      );
      gaEventTracker(
        "google_wallet",
        e?.target?.getAttribute("plan_details_id")
      );
    }

    const payment_id = e?.target?.getAttribute("payment_id");
    const plan_details_id = e?.target?.getAttribute("plan_details_id");
    let url;
    if (payment_id === "9") {
      url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?tab=cct&identity=${identity}&auth=${validUser?.auth}&non_app_source=${validUser?.mobileno}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
    } else {
      url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?identity=${identity}&auth=${validUser?.auth}&non_app_source=${validUser?.mobileno}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
    }
    //console.log(url);
    window.location.href = url;
  };
  // const handleCreditCard = () => {
  //     // console.log("handleCreditCard");
  //     if (creditCardShow === false) {
  //         setCreditCardShow(true);
  //     } else {
  //         setCreditCardShow(false);
  //     }
  // }
  // useEffect(() => {
  //   console.log("isCreditDebit:", isCreditDebit);

  // }, [isCreditDebit]);
  // useEffect(() => {
  let isCardCheck = false;
  isCardCheck = formData?.cardNumber?.trim().length === 8 ? true : false;
  if (isCardCheck) {
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `151check_card_type${formData.cardNumber}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=15&product_id=1&rtype=check_card_type&card_bin=${formData.cardNumber}`,
      headers: {
        'API-KEY': hashKey
      }
    })
      .then((res) => {
        // console.log(res.data);
        const cardType = res?.data?.cardCategory;

        // console.log('res.data?.cardCategory::',cardType,'res.data.cardType',res.data.cardType,typeof cardType,typeof res.data.cardType );
        setIsCreditDebit(cardType);
        if (cardType && cardType === "CC") {
          setIsCategoryCC(true);
        } else {
          setIsCategoryCC(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {payments &&
        payments?.length > 0 &&
        payments
          ?.sort(
            (a, b) =>
              parseFloat(a?.display_order) - parseFloat(b?.display_order)
          )
          .map((data, i) => (
            <div key={i}>
              {data?.payment_name === "Credit Card" && (
                <div className="pay-mode-box" key={i}>
                  <form
                    onSubmit={(e) => validateCardNumberLoc(e, isFreeTrial)}
                    action={`${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?identity=${identity}&auth=${validUser?.auth}&non_app_source=${validUser?.mobileno}&payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`}
                    method="POST"
                  >
                    <input
                      type="hidden"
                      value={`${data?.payment_id}`}
                      name="hiddenpayment_id"
                      id="hiddenpayment_id"
                    />
                    <input
                      type="hidden"
                      value={`${data?.plan_details_id}`}
                      name="hiddenplan_details_id"
                      id="hiddenplan_details_id"
                    />
                    <div
                      className="cchead"
                      onClick={(e) =>
                        setTabHideShow((prevState) => ({
                          ...prevState,
                          upiTab: false,
                          ccTab: !tabHideShow?.ccTab,
                        }))
                      }
                    >
                      <p
                        className="p-t1"
                        onClick={(e) =>
                          setTabHideShow((prevState) => ({
                            ...prevState,
                            upiTab: false,
                            ccTab: !tabHideShow?.ccTab,
                          }))
                        }
                      >
                        Credit/Debit Cards
                      </p>
                      <a
                        href=""
                        className="down-arrow"
                        onClick={
                          (e) => e.preventDefault()
                          // setTabHideShow((prevState) => ({
                          //   ...prevState,
                          //   upiTab: false,
                          //   ccTab: !tabHideShow?.ccTab,
                          // }))
                        }
                      >
                        <img
                          onClick={(e) =>
                            setTabHideShow((prevState) => ({
                              ...prevState,
                              upiTab: false,
                              ccTab: !tabHideShow?.ccTab,
                            }))
                          }
                          src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/arrow-bottom.svg`}
                          alt=""
                        />
                      </a>
                    </div>
                    <div
                      id="cc-details-box"
                      className="drop-down"
                      style={{
                        display: !tabHideShow?.ccTab ? "none" : "block",
                      }}
                    >
                      <div className="ccrow">
                        <input
                          type="text"
                          placeholder="Name"
                          className="ccinput input-field"
                          value={formData?.cardHolderName}
                          name="cardHolderName"
                          onChange={(e) => handleInput(e)}
                        />
                      </div>
                      {isValidName && (
                        <p style={{ color: "red" }}>
                          {/* Please Enter Card Holder Name */}
                          {textData?.pay_cc_enter_card_holder_name_error}
                        </p>
                      )}
                      {isValidNameSp && (
                        <p style={{ color: "red" }}>
                          {/* Please Enter Valid Card Holder Name */}
                          {textData?.pay_cc_enter_card_holder_name_error}
                        </p>
                      )}
                      <div className="ccrow">
                        <NumberFormat
                          className="ccinput input-field"
                          value={formData.cardNumber}
                          name="cardNumber"
                          id="cardNumber"
                          onChange={(e) => handleInput(e)}
                          placeholder="Card Number"
                          format="#### #### #### ####"
                          autoComplete="off"
                        />
                      </div>
                      {isValidCardNumber && (
                        <p style={{ color: "red" }}>
                          {/* Please Enter Valid Card Number */}
                          {textData?.pay_cc_enter_valid_card_umber_error}
                        </p>
                      )}
                      <div className="ccrow">
                        <div className="cc-left-box">
                          <NumberFormat
                            value={formData.expiryDate}
                            id="expiryDate"
                            name="expiryDate"
                            onChange={(e) => handleInput(e)}
                            className="ccinput input-field"
                            placeholder="MM/YY"
                            format="##/##"
                            mask={["M", "M", "Y", "Y"]}
                          />
                          {isValidMonth && (
                            <p style={{ color: "red" }}>
                              {/* Please Enter Valid Expiry Date */}
                              {textData?.pay_cc_valid_expiry_error}
                            </p>
                          )}
                        </div>
                        <div className="cc-right-box">
                          <NumberFormat
                            maxLength="4"
                            type="password"
                            value={formData.cvv}
                            id="cvv"
                            name="cvv"
                            onChange={(e) => handleInput(e)}
                            className="ccinput input-field"
                            placeholder="CVV"
                          />
                          {isValidCvv && (
                            <p style={{ color: "red" }}>
                              {/* Please Enter CVV Number */}
                              {textData?.pay_please_enter_cvv_number_error}
                            </p>
                          )}
                        </div>
                        <input type="hidden" name="pg" value={isCreditDebit} />
                      </div>
                      <div className="ccrow text-center">
                        <button
                          className="blue-btn"
                          disabled={isAgree === false ? true : false}
                          type="submit"
                          plan_details_id={data.plan_details_id}
                          payment_id={data.payment_id}
                        >
                          Pay Now
                        </button>
                      </div>
                      <div className="savesecurepayment">
                        <img
                          src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/lock-icon.svg`}
                          className="lockicon"
                          alt=""
                        />
                        Save and Secure Payment
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          ))}
    </>
  );
};
export default Payments;
