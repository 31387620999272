import React from "react";
import { Image } from "./Elements";
//const tickImg = "tickimg.svg";
export default function Features({ language }) {
  // console.log(img1);
  // props { title, img1 = "", img2 = "", img3 = "" }
  //crpssimg.svg
  //tickimg.svg

  return (
    <>
      <tr>
        <td>{language?.pay_features_list_1}</td>
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
        {/* <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td> */}
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
      <tr>
        <td>{language?.pay_features_list_2}</td>
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
        {/* <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td> */}
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
      <tr>
        <td>{language?.pay_features_list_3}</td>
        <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td>
        {/* <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td> */}
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
      {/* <tr>
        <td>{language?.pay_features_list_4}</td>
        <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td>

        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr> */}
      <tr>
        <td>{language?.pay_features_list_5}</td>
        <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td>
        {/* <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td> */}
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
      <tr>
        <td>{language?.pay_features_list_6}</td>
        <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td>
        {/* <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td> */}
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
      <tr>
        <td>{language?.pay_features_list_7}</td>
        <td className="CTR">
          <Image src={`crpssimg.svg`} alt={`cross img`} />
        </td>
        <td className="CTR">
          <Image src={`tickimg.svg`} alt={`tick img`} />
        </td>
      </tr>
    </>
  );
}
