/* eslint-disable  */
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import crypto from "crypto-browserify";
import { useTimer } from "react-timer-hook";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPopUpEvent,
  getParam,
} from "../../utils";

const UpiPayments = ({
  plan_id,
  planData,
  uL,
  identity,
  validUser,
  product_id,
  platform_id,
  textData,
  upiPayments,
  urlparams,
  coupon_code,
  pushbar,
  setPushbar,
  tabHideShow,
  setTabHideShow,
  isFreeTrial,
}) => {
  const upi = "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/upi.svg";
  const gpay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/gpay.svg";
  const paytm =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/paytm.svg";
  const phonepay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/phonepay.svg";

  const [time, setTime] = useState();

  // const ref = useRef();
  let ulLowerCase = uL?.toLowerCase();
  //PhonePe remove on build number condition
  /*
  As per teams call enabling PhonePe for intent by commenting this block
  let upiListArr = ulLowerCase?.split(",");
  if (ulLowerCase !== "" && parseInt(buildNumber) < 511) {
    //remove phonepay
    upiListArr = upiListArr?.filter((el) => el !== "phonepe");
    ulLowerCase = upiListArr?.join();
  }
  */
  // console.log("ulLowerCase::", ulLowerCase);

  // const [pushbar, setPushbar] = useState({ openCoupon: false, Verifybottom1: false, Verifybottom2: false });
  const [isUpiValid, setIsUpiValid] = useState(false);
  const [upiPopName, setUpiPopName] = useState("UPI App");
  const [logoType, setLogoType] = useState(upi);
  // const [isTimer, setIsTimer] = useState(false);
  const [showUPIError, setShowUPIError] = useState(false);
  // const [plan, setPlan] = useState({ discountPrice: " " });
  const [txnId, setTxnId] = useState("");

  const [isUpiBtnDisable, setUpiBtnDisable] = useState(false);
  const [isAgree, setIsAgree] = useState(true);

  /*
     //control autopay checkbox for users
    let allowCheckConsent=[];
    if(allowCheckConsent.includes(identity) && planType!=="event" && !/vod/.test(planType) ){
        isValidUser=true;
    }
    */
  let isValidUser = false;
  const planType = getParam(urlparams, "plan_type").toLocaleLowerCase();
  /* exclude autopay consent checkbox for 3 days plan i.e plan id 57 */
  if (
    planType !== "event" &&
    !/vod/.test(planType) &&
    plan_id !== "57" &&
    plan_id !== "10"
  ) {
    isValidUser = true;
  }

  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const verifyText = (textData, isUpiValid = false) => {
    let text = "";
    if (isUpiValid) {
      // if (textData?.secondLang?.pay_verified) {
      //   text = textData?.secondLang?.pay_verified;
      // } else {
      //   text = textData?.default?.pay_verified;
      // }
      text = textData?.pay_verified;
    } else {
      // if (textData?.secondLang?.pay_verify) {
      //   text = textData?.secondLang?.pay_verify;
      // } else {
      //   text = textData?.default?.pay_verify;
      // }
      text = textData?.pay_verify;
    }
    return text;
  };
  // console.log("child Upi = " + coupon_code);

  const handlePay = (e) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_PayNowConfirmation_UPI",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_PayNowConfirmation_UPI"
    );

    ampRabbitMQPopUpEvent({
      identity: identity,
      "Plan Name": planData?.plan_name,
      "Plan Validity": planData?.plan_valid,
      "Plan Value": planData?.plan_price?.toFixed(2),
      "Plan Currency": planData?.plan_currency,
      "Plan ID": plan_id,
      product_id: product_id,
      platform_id: platform_id,
      Action: "UPI_Timer",
    });

    const payment_id = e?.target?.getAttribute("payment_id");
    gaEventTracker("pay_now", planData?.plan_name + "_" + planData?.plan_price);

    const plan_details_id = e?.target?.getAttribute("plan_details_id");
    const urlCreateRequest = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?identity=${identity}&auth=${validUser?.auth}&non_app_source=${validUser?.mobileno}&product_id=${product_id}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=UPI&bankcode=UPI&type=upi_collect&vpa=${formData?.upiid}&${urlparams}&coupon_code=${coupon_code}`;
    handleElement(e);
    // setIsTimer(true);
    setPushbar((prev) => {
      return {
        ...prev,
        Verifybottom1: false,
        isTimer: true,
      };
    });
    axios
      .get(urlCreateRequest)
      .then((res) => {
        // console.log("handle pay::", res);
        if (
          res?.data?.result?.status === "pending" &&
          res?.data?.result?.txnid !== ""
        ) {
          setTxnId(res?.data?.result?.txnid);
          setTimeout(() => {
            checkUpiPaymentStatus(
              false,
              payment_id,
              plan_details_id,
              res?.data?.result?.txnid
            );
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MyTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
         console.warn("onExpire called");
         const storedWebsite = localStorage.getItem("website");
         if(storedWebsite == 'true' || storedWebsite == true){
        window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&website=true`;
         }
         else{
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}`;
         }
      },
    });
    return (
      <>
        <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
        <span>{seconds < 10 ? "0" + seconds : seconds}</span>
      </>
    );
  };
  /*
  const verifyCoupon = (e) => {

      if (e) e.preventDefault();

      const url = `${process.env.REACT_APP_HANGAMA_URL}/page/index.php?c=api&m=execute_coupon&coupon_code=${formData.couponcode}&${urlparams}`;
      // console.log(url);
      axios
          .get(url)
          .then((res) => {
              // console.log("=========");
              // console.log(res);
              // console.log("=========");
              const couponDiscount = get(res, 'data.coupon_details.value', "");
              // console.log("coupon discount" + couponDiscount);
              if (couponDiscount !== "" || null || undefined) {
                  setFormData((prev) => {
                      return { ...prev, couponVerified: true }
                  })
                  setPushbar((prev) => {
                      return { ...prev, openCoupon: false }
                  });
                  setPlan((prev) => {
                      return { ...prev, discountPrice: plan.plan_price - couponDiscount }
                  });
              }
          })
          .catch((err) => {
              console.log(err);
          });
  }
  */

  const handleElement = (e) => {
    const close = e?.target?.getAttribute("close");

    if (close === "close") {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_CloseVerification_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_CloseVerification_UPI"
      );
    }

    if (close === "closevpa") {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_CloseVPA_Confirmation_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_CloseVPA_Confirmation_UPI"
      );
    }

    const name = e?.target?.getAttribute("data-name");
    let value = e?.target?.getAttribute("value");
    const upiid = formData?.upiid;
    let upiiType = upiid?.split("@")?.pop();
    upiTypeLogo(upiiType);
    setPushbar((prev) => {
      // console.log(value);
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
    // console.log(pushbar);
  };

  const upiTypeLogo = (upiType = "upi") => {
    if (upiType?.toLowerCase() === "upi")
      return setLogoType(upi) & setUpiPopName("UPI App");
    if (upiType?.toLowerCase() === "paytm")
      return setLogoType(paytm) & setUpiPopName("Paytm App");
    if (upiType?.toLowerCase() === "gpay")
      return setLogoType(gpay) & setUpiPopName("Gpay App");
    if (upiType?.toLowerCase() === "ybl")
      return setLogoType(phonepay) & setUpiPopName("PhonePe App");
  };

  const [formData, setFormData] = useState({
    // couponcode: couponcode,
    couponVerified: false,
    upiid: "",
  });

  const handleVerify = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Verify_UPI", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Verify_UPI"
    );
    const payment_id = e?.target?.getAttribute("payment_id");
    const upiid = formData?.upiid;
    // console.log(planData);
    //return;
    gaEventTracker("UPI", planData?.plan_name + "_" + planData?.plan_price);
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `${payment_id}${product_id}check_vpa${upiid}${coupon_code}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    const urlVerify = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=check_vpa&vpa=${upiid}&coupon_code=${coupon_code}`;
    axios
      .get(urlVerify,{
        headers: {
          'API-KEY': hashKey
        }})
      .then((res) => {
        if (res?.data?.isVPAValid === 0) {
          setIsUpiValid(false);
          setShowUPIError(true);
        }
        if (res?.data?.isVPAValid) {
          setIsUpiValid(true);
          setShowUPIError(false);

          //open UPI confirm : old upi collect flow
          // handleElement(e);

          //open timer popup directly & notify user,skip confirmation popup [new upi collect flow] : 13 June 2023
          setPushbar((prev) => {
            // console.log(value);
            return { ...prev, Verifybottom2: true }; //value === "true" ? true : false
          });
          handlePay(e);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    // console.log(formData);
  };

  const handleInput = (e) => {
    const { name, value } = e?.target;
    // console.log(name);
    let data = { ...formData };
    // console.log();
    data[name] = value;

    setFormData(data);
    setIsUpiValid(false);
  };

  // stop calling checkUpiPaymentStatus when pending status enquiry hits(counter) to 200
  let upiPaymentStatusCounter = 0;
  const checkUpiPaymentStatus = (
    refresh,
    payment_id,
    plan_details_id,
    txnid
  ) => {
    // console.log("payment_id"+payment_id+"plan_details_id"+plan_details_id+"txn"+txnid);
    if (refresh) {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_RefreshVerification_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_RefreshVerification_UPI"
      );
    }
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `${payment_id}${product_id}order_status_check${txnid}${coupon_code}${identity}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    const urlCheckStatus = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=order_status_check&order_id=${txnid}&coupon_code=${coupon_code}&identity=${identity}`;
    axios
      .get(urlCheckStatus,{
        headers: {
          'API-KEY': hashKey
        }})
      .then((res) => {
        console.log('checkUpiPaymentStatus',res);
        // console.log();
        if (res?.data?.transaction_details[txnid]?.status === "failure") {
          console.log("enter in failaure");
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "success"
        ) {
          ampRabbitMQPopUpEvent({
            identity: identity,
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id,
            product_id: product_id,
            platform_id: platform_id,
            Action: "UPI_Confirm",
          });
          console.log("nnnnnnnn");
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "pending"
        ) {
          // console.log("upiPaymentStatusCounter", upiPaymentStatusCounter);
          if (upiPaymentStatusCounter < 199)
            setTimeout(() => {
              checkUpiPaymentStatus(false, payment_id, plan_details_id, txnid);
              upiPaymentStatusCounter++;
            }, 3000);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const intentData = async (pg, bankcode, upi_type, data) => {
    // const body = {
    //     "product_id": product_id,
    //     "platform_id": platform_id,
    //     "country": country,
    //     "plan_id": plan_id,
    //     "plan_type": plan_type,
    //     "content_id": content_id
    // };

    let res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?identity=${identity}&auth=${validUser?.auth}&non_app_source=${validUser?.mobileno}&upi_type=${upi_type}&payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${coupon_code}`,
    })
      .then((res) => {
        // console.log(res);
        const response = res?.data;
        return response;
      })
      .catch((e) => {
        console.log(e);
      });

    return res;
  };

  const intenFlow = async (e, data) => {
    // console.log(data);
    // amplitudeBtnEvent(identity, "Btn_Submit_UPI", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_UPI"
    );
    const payment_id = data?.payment_id;
    const plan_details_id = data?.plan_details_id;
    const upi_type = data?.upi_type.toLowerCase();

    let response = {};
    // if (upi_type === "bhim") {
    //     response.type = "UPI";
    //     response.bankcode = "INTENT";
    // } else if (upi_type === "gpay") {
    //     response.type = "TEZ";
    //     response.bankcode = "TEZ";
    // } else if (upi_type === "phonepe") {
    //     response.type = "PPIN";
    //     response.bankcode = "INTENT";
    // }
    response.type = "UPI";
    response.bankcode = "INTENT";

    console.log(response);
    const intentRes = await intentData(
      response.type,
      response.bankcode,
      upi_type,
      data
    );

    const url = intentRes?.url ? intentRes?.url : "";
    const status = intentRes?.status ? intentRes?.status : "";
    const order_id = intentRes?.order_id ? intentRes?.order_id : "";
    // console.log(intentRes);
    if (status === "success") {
      //setTimeout(() => {
      checkUpiPaymentStatus(false, payment_id, plan_details_id, order_id);
      //}, 2000);
      window.location.href = url;
    }
  };

  let isUpiFound = false;
  upiPayments
    .sort((a, b) => parseFloat(a?.display_order) - parseFloat(b?.display_order))
    .forEach((data) => {
      if (ulLowerCase?.includes(data?.payment_name?.toLowerCase())) {
        isUpiFound = true;
      }
    });
  const gaEventTracker = useAnalyticsEventTracker("UPI Payment");

  // useEffect(()=>{
  //     if(pushbar.Verifybottom1){
  //         document.body.style.overflow = 'hidden';
  //     }
  //     else if(pushbar.Verifybottom2){
  //         document.body.style.overflow = 'hidden';
  //     }
  //     else {
  //         document.body.style.overflow = '';
  //     }
  // },[pushbar])
  useEffect(() => {
    // console.log('timer::',isTimer)
    if (pushbar?.Verifybottom2 && pushbar?.isTimer) {
      const time = new Date();
      time.setSeconds(time?.getSeconds() + 300); // 10 minutes timer
      setTime(time);
    }
  }, [pushbar?.Verifybottom2, pushbar?.isTimer]);

  return (
    <>
      {upiPayments && upiPayments?.length > 0 && (
        <>
          <div className="pay-mode-box">
            <div className="cchead">
              <p
                className="p-t1"
                onClick={(e) =>
                  setTabHideShow((prevState) => ({
                    ...prevState,
                    upiTab: !tabHideShow?.upiTab,
                    ccTab: false,
                  }))
                }
              >
                UPI Payment
              </p>
              <a
                href=""
                id="payment-box-arrow"
                className="down-arrow rotate-arrow"
                onClick={
                  (e) => e.preventDefault()
                  // setTabHideShow((prevState) => ({
                  //   ...prevState,
                  //   upiTab: !tabHideShow?.upiTab,
                  //   ccTab: false,
                  // }))
                }
              >
                <img
                  onClick={(e) =>
                    setTabHideShow((prevState) => ({
                      ...prevState,
                      upiTab: !tabHideShow?.upiTab,
                      ccTab: false,
                    }))
                  }
                  src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/arrow-bottom.svg`}
                  alt="arrow"
                />
              </a>
            </div>
            <div id="payment-box" className="drop-down">
              {
                //free trial check added
                isFreeTrial?.status === false &&
                isFreeTrial?.plan_id === plan_id ? (
                  ""
                ) : uL !== "" && upiPayments && upiPayments?.length > 0 ? (
                  <ul className="inline-list">
                    {upiPayments
                      ?.sort(
                        (a, b) =>
                          parseFloat(a?.display_order) -
                          parseFloat(b?.display_order)
                      )
                      .map(
                        (data, i) =>
                          ulLowerCase?.includes(
                            data?.payment_name?.toLowerCase()
                          ) && (
                            <li key={i} onClick={(e) => intenFlow(e, data)}>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  // intenFlow(e, data);
                                }}
                              >
                                <img src={data?.payment_logo} alt="" />
                                <span className="paym-name small">
                                  {data?.payment_name}
                                </span>
                              </a>
                            </li>
                          )
                      )}
                  </ul>
                ) : (
                  ""
                )
              }

              <div style={{ display: !tabHideShow?.upiTab ? "none" : "block" }}>
                {isUpiFound && upiPayments?.length > 0 && (
                  <p className="upi-label">
                    <span>Or Enter UPI ID</span>
                  </p>
                )}

                <div className={`form-sec upi-box `}>
                  <input
                    className={`input-field  ${
                      showUPIError ? "error-border" : ""
                    }`}
                    autoComplete="off"
                    type="text"
                    placeholder="UPI ID (username@bankname)"
                    onChange={(e) => handleInput(e)}
                    name="upiid"
                  />
                </div>
                {showUPIError && (
                  <p className="error-msg">Please enter a valid UPI Id</p>
                )}
                <button
                  type="button"
                  className="blue-btn"
                  data-name="Verifybottom1"
                  plan_details_id={
                    upiPayments[0]?.plan_details_id &&
                    upiPayments[0]?.plan_details_id
                  }
                  payment_id={
                    upiPayments[0]?.payment_id && upiPayments[0]?.payment_id
                  }
                  value={true}
                  onClick={(e) => handleVerify(e)}
                >
                  Pay Now
                </button>
                {/* consent here */}
              </div>
            </div>
          </div>
        </>
      )}

      {/* verify popup */}

      {/* timer popup */}
      <aside
        data-pushbar-id="bottom1"
        style={{
          pointerEvents:
            pushbar?.Verifybottom2 && pushbar?.isTimer === true
              ? "auto"
              : "none",
        }}
        className={
          pushbar?.Verifybottom2 && pushbar?.isTimer === true
            ? `pushbar from_bottom ht75 opened`
            : `pushbar from_bottom ht75`
        }
      >
        <div className="pop-up-wrapper">
          <div className="upi-popup-content">
            <div className="p-paymenticon">
              <img src={logoType} className="p-payicon" alt="" />
            </div>
            <p className="p-txt1">Open {upiPopName}</p>
            <p className="p-txt2">please wait verifying payment</p>
            <p className="p-upiid">
              {pushbar?.Verifybottom2 && pushbar?.isTimer === true ? (
                <MyTimer expiryTimestamp={time} />
              ) : (
                ""
              )}
            </p>
            <div className="p-paybtnbox">
              <div
                href=""
                className="p-cancel-btn"
                data-pushbar-close=""
                data-name="Verifybottom2"
                value={false}
                onClick={(e) => {
                  handleElement(e);
                  setUpiBtnDisable(false);
                }}
              >
                Close
              </div>
              <div
                className="p-blue-btn"
                data-pushbar-target="bottom2"
                refresh="true"
                plan_details_id={
                  upiPayments &&
                  upiPayments[0]?.plan_details_id &&
                  upiPayments[0]?.plan_details_id
                }
                payment_id={
                  upiPayments &&
                  upiPayments[0]?.payment_id &&
                  upiPayments[0]?.payment_id
                }
                onClick={(e) =>
                  checkUpiPaymentStatus(
                    true,
                    e.target.getAttribute("payment_id"),
                    e.target.getAttribute("plan_details_id"),
                    txnId
                  )
                }
              >
                Refresh
              </div>
            </div>
            <div className="p-securebox">
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/mobile/non-app-pay-camp/images/lock-icon.svg`}
                className="lockicon"
                alt=""
              />
              Save and Secure Payment
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
export default UpiPayments;
